import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLazyQuery } from '@apollo/react-hooks';
import { useSelector } from 'react-redux';
import { navigate } from 'gatsby';
import { useQueryParam } from 'gatsby-query-params';
import { Box } from '@material-ui/core';
import {
  BurstMode,
  FolderSharedOutlined, OpenInNew,
  Person,
  SelectAll,
} from '@material-ui/icons';

import { Navbar } from '../Navbar/styled/NavbarStyles';
import Loading from '../Common/Loading';
import { SearchFilters } from './SearchFilters';
import { GET_SEARCH } from '../../queries/Search/Search';
import { ContainerUI } from '../Common/styled/ContainerUI';
import { ScrollableContainer } from '../Common/ScrollableContainer';
import { SectionBar } from '../../componentsUI/SectionBar';
import TableCollapse from '../../componentsUI/tableCollapse';
import { InnerSpanHTML, InnerDivHTML } from '../Common/InnerHTML';
import { getDateFromDateTimeText } from '../../utils/dateTimeUtils';
import { GetItemType } from '../../utils/functions';
import { AlertUI, AlertWrapperUI } from '../../componentsUI/Alert';
import useStyles from './styled/SearchMakeStyles';
import { SectionContainer } from '../Section/styled/StyledSection';

export const SearchView = () => {
  const { t } = useTranslation();
  const styles = useStyles();
  const hospitalUuid = useSelector((state) => state.hospital.uuid);
  const search = useQueryParam('q');
  const [filters, setFilters] = useState({ show: 'all' });
  const [selected, setSelected] = useState([]);

  function updateFilter(filter, value) {
    const update = { ...filters, [filter]: value };
    Object.keys(update).forEach((key) => !update[key] && delete update[key]);

    setFilters(update);
  }

  const cleanedFilters = () => {
    const clean = { ...filters };
    Object.keys(clean).forEach((key) => {
      if (clean[key] === '') delete clean[key];
    });
    return clean;
  };

  const [getSearch, { loading, data, fetchMore }] = useLazyQuery(
    GET_SEARCH,
    {
      variables: {
        first: 15,
        hospitalUuid,
        search,
        ...cleanedFilters(),
      },
    },
  );

  useEffect(() => {
    if (search) getSearch();
  }, [search, filters]);

  const fetchMoreFunction = (fetchMoreCb) => {
    // TODO: in updateQuery add newResults to all previousResults, not only 'patients'
    const type = 'patients';

    const patientsEndCursor = data.patients.pageInfo.endCursor;
    const medicalCasesEndCursor = data.medicalCases.pageInfo.endCursor;
    const dicomStudiesEndCursor = data.dicomStudies.pageInfo.endCursor;

    fetchMore({
      query: GET_SEARCH,
      variables: {
        patientsCursor: patientsEndCursor,
        casesCursor: medicalCasesEndCursor,
        studiesCursor: dicomStudiesEndCursor,
        first: 20,
        hospitalUuid,
        search,
        ...cleanedFilters() },
      updateQuery: (previousResult, { fetchMoreResult }) => {
        const newEdges = fetchMoreResult[type].edges;

        return newEdges.length ? {
          ...previousResult,
          [type]: {
            ...previousResult[type],
            pageInfo: fetchMoreResult[type].pageInfo,
            edges: [...previousResult[type].edges, ...newEdges],
          },
        } : previousResult;
      },
    }).then(({ data: fetchMoreData }) => (
      fetchMoreData[type].pageInfo.hasNextPage && fetchMoreCb()
    ));
  };

  const showPatients = ['all', 'patients'].includes(filters.show);
  const showMedicalCases = ['all', 'medicalCases'].includes(filters.show);
  const showDicomStudies = ['all', 'dicomStudies'].includes(filters.show);

  const searchItems = data && [
    ...(showPatients && data.patients) ? data.patients.edges.map((edge) => edge.node) : [],
    ...(showMedicalCases && data.medicalCases) ? data.medicalCases.edges.map((edge) => edge.node) : [],
    ...(showDicomStudies && data.dicomStudies) ? data.dicomStudies.edges.map((edge) => edge.node) : [],
  ];

  const setFilterToAll = () => {
    updateFilter('show', 'all');
    setSelected([]);
  };

  const setFilterToPatients = () => {
    updateFilter('show', 'patients');
    setSelected([]);
  };

  const setFilterToCases = () => {
    updateFilter('show', 'medicalCases');
    setSelected([]);
  };

  const setFilterToStudies = () => {
    updateFilter('show', 'dicomStudies');
    setSelected([]);
  };

  const openSearchItem = (index) => {
    const item = searchItems[index];
    const type = GetItemType(item).toLowerCase();

    switch (type) {
      case 'patient':
        navigate(`/patient/${item.uuid}`);
        break;
      case 'medicalcase':
        navigate(`/case/${item.uuid}`);
        break;
      case 'dicomstudy':
      default:
        navigate(`/study/${item.uuid}`);
        break;
    }
  };

  const handleGoto = (event, index) => openSearchItem(index);
  const handleGotoSelected = () => openSearchItem(selected[0]);

  const buttons = [
    { name: 'all.results', icon: SelectAll, handleClick: setFilterToAll, disabled: false, selected: filters.show === 'all' },
    { name: 'divider2', type: 'divider' },
    { name: 'patients', icon: Person, handleClick: setFilterToPatients, disabled: false, selected: filters.show === 'patients' },
    { name: 'cases', icon: FolderSharedOutlined, handleClick: setFilterToCases, disabled: false, selected: filters.show === 'medicalCases' },
    { name: 'studies', icon: BurstMode, handleClick: setFilterToStudies, disabled: false, selected: filters.show === 'dicomStudies' },
    { name: 'divider2', type: 'divider' },
    { name: 'open', icon: OpenInNew, handleClick: handleGotoSelected, disabled: !selected.length },
  ];

  const getFilterTitle = (show) => {
    switch (show) {
      case 'patients':
        return t('patients');
      case 'medicalCases':
        return t('medical.case');
      case 'dicomStudies':
        return t('studies');
      case 'all':
      default:
        return t('all.results');
    }
  };

  const getItemTypeDescription = (item) => {
    const type = GetItemType(item).toLowerCase();

    switch (type) {
      case 'patient':
        return type;
      case 'medicalcase':
        return 'medical.case';
      case 'dicomstudy':
      default:
        return 'dicom.study';
    }
  };

  const getTitle = (item) => {
    const type = GetItemType(item);

    switch (type) {
      case 'Patient':
        return `${item.name} ${item.surname}`;
      case 'MedicalCase':
        return item.title;
      default:
        return item.title;
    }
  };

  const getSubTitle = (item) => {
    const type = GetItemType(item);

    switch (type) {
      case 'Patient':
        return item.customId;
      case 'MedicalCase':
        return `${item.patient.name} ${item.patient.surname}`;
      default:
        return <InnerSpanHTML content={item.description} />;
    }
  };

  const getCollapse = (object) => {
    const type = getItemTypeDescription(object.item);
    const content = `${t('type')}: <b>${t(type)}</b> &nbsp;
      ${t('created.at')}: <b>${getDateFromDateTimeText(object.item.createdAt)}</b> &nbsp;
      ${t('last.update')}: <b>${getDateFromDateTimeText(object.item.updatedAt)}</b>`;

    return <InnerDivHTML content={content} style={{ margin: '0 0 10px 50px', fontSize: '1.25rem' }} />;
  };

  const morePatients = data && data.patients && data.patients.pageInfo && data.patients.pageInfo.hasNextPage;
  const moreMedicalCases = data && data.medicalCases && data.medicalCases.pageInfo && data.medicalCases.pageInfo.hasNextPage;
  const moreDicomStudies = data && data.dicomStudies && data.dicomStudies.pageInfo && data.dicomStudies.pageInfo.hasNextPage;
  const moreSearch = morePatients || moreMedicalCases || moreDicomStudies;

  return (
    <ContainerUI>
      <Navbar>
        <SectionBar title="search" subtitle={getFilterTitle(filters.show)} items={buttons} />
      </Navbar>

      <Box className={styles.relative}>
        <Box className={styles.filters}>
          <SearchFilters filters={filters} updateFilter={updateFilter} />
        </Box>

        <Box className={styles.result}>
          <ScrollableContainer>
            <SectionContainer>
              {loading && <Loading />}
              {!loading && searchItems && !searchItems.length && (
                <AlertWrapperUI>
                  <AlertUI severity="warning" title="Info">
                    {t('no.results.have.been.found')}
                  </AlertUI>
                </AlertWrapperUI>
              )}
              {searchItems && !!searchItems.length && (
                <TableCollapse
                  responsive={false}
                  fieldNames={[]}
                  items={searchItems}
                  GetTitle={getTitle}
                  GetSubtitle={getSubTitle}
                  GetCollapse={getCollapse}
                  handleGoto={handleGoto}
                  selected={selected}
                  setSelected={setSelected}
                  isMore={moreSearch}
                  fetchMoreFn={fetchMoreFunction}
                  isSearch
                />
              )}
            </SectionContainer>
          </ScrollableContainer>
        </Box>
      </Box>

    </ContainerUI>
  );
};
